




































































































































@import '@design';

.menu-section {
  position: relative;
  margin-bottom: 2.5em;
  &.subsection {
    margin-top: 50px;
    margin-bottom: 0;
  }
  &:not(.subsection):not(.callout) {
    padding-top: 0.5em;
  }

  &.isDirty {
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 5px;
      content: '';
      background: aquamarine;
      transform: translateY(-100%);
    }
  }
  &.callout {
    padding-top: 1.3em;
    padding-bottom: 1.3em;
    margin-right: auto;
    margin-left: auto;
    border: 2px solid #979797;
    &.has-title .menu-section--items {
      margin: 1em 0 0;
    }
  }
} // .menu-section

.menu-section--title {
  margin-bottom: 0;
  font-size: 2.4em;
  line-height: 1.1;
  &.placeholder {
    position: relative;
    z-index: 10;
    font-style: italic;
    color: darken(white, 25%) !important;
  }
}

.menu-section--subtitle .resto-editable {
  font-size: 0.95em;
  font-weight: 400 !important;
  text-transform: uppercase;
}

.menu-entity--buttons-wrap.Section-buttons {
  z-index: 929;

  $distance: 30px;

  &.top {
    top: 0;
    padding-bottom: $distance;
    transform: translateY(-100%);
  }
  &.bottom {
    top: auto;
    bottom: 0;
    padding-top: $distance;
    transform: translateY(100%);
  }
}

.list-enter-active,
.list-leave-active {
  transition: all 2s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0.1;
  transform: translateY(100%);
}
